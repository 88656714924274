.attendanceEmployee {
    

    .textContainer {
        padding: 20px 0;
    }

    .boxes {
        display: grid;
        grid-template-columns: 33% 33% 33%;
        gap: 30px;
        margin-right: 50px;

        .content {
            padding: 20px 20px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            background-color: white;
            box-shadow: 4px 4px 10px lightgray;
            border-radius: 5px;

            h4 {
                color: black;

                span {
                    color: gray;
                }
            }

            .punch {
                width: 100%;
                padding: 4px 16px;
                border-radius: 5px;
                border: 1px solid lightgray;
                line-height: 0%;

                h6 {
                    color: black;
                }
            }

            .time {
                width: 120px;
                height: 120px;
                margin: auto;
                border: 4px solid lightgray;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .punchBtn {
                color: white;
                background-color: #58a6ff;
                font-size: 16px;
                font-weight: bold;
                padding: 10px 30px;
                border-radius: 20px;
                border: none;

                &:hover {
                    background-color: #509bf1;
                }
            }

            .extra {
                display: flex;
                justify-content: space-between;
                gap: 10px;
                text-align: center;

                .break,
                .overtime {
                    width: 100%;
                    padding: 2px 10px;
                    border-radius: 5px;
                    line-height: 0%;
                    border: 1px solid lightgray;

                    h6 {
                        color: black;
                    }

                    p {
                        font-weight: bold;
                    }
                }
            }

            .data {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .progressData {
                    width: 100%;
                    padding: 0 16px;
                    border-radius: 5px;
                    border: 1px solid lightgray;
                    line-height: 0%;


                    .title {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;


                        p{
                            font-weight: bold;
                            margin-top: 14px;
                        }
                    }
                }

            }
        }

        .activity{
            padding: 10px 20px;
            display: flex;
            flex-direction: column;
            gap: 2px;
            background-color: white;
            box-shadow: 4px 4px 10px lightgray;
            border-radius: 5px;
            line-height: 0%;
        }
        
    }

    .input {
        display: grid;
        grid-template-columns: 25% 25% 25% 25% ;
        column-gap: 20px;
        margin-top: 20px;
        margin-right: 60px;

        button {
            height: 3.5rem;
            border-radius: 5px;
        }
    }
}