.leaveSettings {
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0;


        .actionContainer {
            display: flex;
            align-items: center;
            gap: 10px;


            button {
                margin-left: 16px;
                border-radius: 50px;
                padding: 10px;
                font-size: 14px;
                font-weight: 600;
                border-color: #58a6ff;

                &:hover {
                    background-color: #58a6ff;
                }
            }
        }
    }

    .content {
        padding: 10px;
        background-color: white;
        border-radius: 5px;
        box-shadow: 2px 2px 10px lightgray;


        .titleDiv {
            display: flex;
            justify-content: space-between;

            .toggleBtnOn {
                display: flex;
                align-items: center;
                gap: 6px;
                color: white;
                background-color: #58a6ff;
                padding: 2px 16px;
                border-radius: 20px;
                border: none;
                font-size: 16px;
                font-weight: bold;


                i {
                    color: white;
                    background-color: white;
                    border-radius: 100%;
                    width: 20px;
                    height: 20px;
                }
            }

            .toggleBtnOff {
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                gap: 6px;
                color: gray;
                background-color: white;
                padding: 2px 16px;
                border: 2px solid lightgray;
                border-radius: 20px;
                font-size: 16px;
                font-weight: bold;


                i {
                    color: gray;
                    background-color: gray;
                    border-radius: 100%;
                    width: 20px;
                    height: 20px;
                }
            }

        }

        .formDiv {
            width: 75%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            Form {
                width: 75%;
            }

            .editBtn {
                border: none;
                background-color: transparent;
                color: #58a6ff;
                font-size: 16;
                font-weight: 600;
            }

            .actionBtn {
                display: flex;
                align-items: center;
                gap: 10px;


                .cancel {
                    color: lightgray;
                    background-color: transparent;
                    border: 1px solid lightgray;
                    font-size: 16;
                    font-weight: 600;
                    padding: 2px 10px;
                    border-radius: 5px;
                }

                .save {
                    color: white;
                    background-color: #58a6ff;
                    border: none;
                    font-size: 16;
                    font-weight: 600;
                    padding: 2px 10px;
                    border-radius: 5px;
                }
            }

            .forward {
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                gap: 20px;

                .check {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                }
            }

            .check {
                display: flex;
                align-items: center;
                gap: 4px;
            }

        }
    }

    .policy {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        button {
            margin-left: 16px;
            border-radius: 10px;
            padding: 10px;
            font-size: 14px;
            font-weight: 600;
            border: none;
            background-color: #58a6ff;
            color: white;

            &:hover {
                background-color: #5c9de7;
            }
        }
    }

}