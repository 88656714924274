.allEmployees {

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0;


        .actionContainer {
            display: flex;
            align-items: center;
            gap: 10px;


            i {
                padding: 1px 5px;
                color: black;
                background-color: white;
                border: 1px solid gray;
                border-radius: 5px;
                cursor: pointer;
                transition: all 0.1s ease;


                &:active {
                    scale: 2;
                    animation-duration: 100;
                }

                &:hover {
                    background-color: #58a6ff;
                    color: white;
                }
            }

            .activeBtn {
                padding: 1px 5px;
                color: white;
                background-color: #58a6ff;
                // border: 1px solid gray;
                // border-radius: 5px;
                // cursor: pointer;
                // transition: all 0.1s ease;
            }

            button {
                margin-left: 16px;
                border-radius: 50px;
                padding: 10px;
                font-size: 14px;
                font-weight: 600;
                border-color: #58a6ff;

                &:hover {
                    background-color: #58a6ff;
                }
            }
        }
    }

    .wrapper {

        .input {
            display: grid;
            grid-template-columns: 25% 25% 25% 25%;
            column-gap: 20px;
            margin-right: 70px;
        }

        .employeesCard {
            display: grid;
            grid-template-columns: 25% 25% 25% 25%;
            column-gap: 20px;
            margin-right: 70px;
        }

        button {
            height: 3.5rem;
            border-radius: 5px;
        }


        .employeeCard {
            position: relative;
            height: 200px;
            padding: 20px;

            .action {
                position: absolute;
                right: 7px;
                top: 12px;
                z-index: 100;
            }

            img,
            h4 {
                cursor: pointer;
            }
        }
    }

}