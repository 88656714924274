.attendanceAdmin {
    .textContainer {
        padding: 20px 0;
    }
}

.modal{

    .body{
        background-color: white;
        margin: 40px;
        border-radius: 5px;
        box-shadow: 2px 2px 5px;

        .content{
            padding: 4px;
            display: flex;
            flex-direction: column;
            gap: 10px;

            h4{
                color: black;
                span{
                    color:gray;
                }
            }

            .punch{
                width: 100%;
                border: 1px solid lightgray;
                padding: 2px 10px;
                border-radius: 5px;
                line-height: 0%;

                h6{
                    color: black;
                }
                p{
                    font-weight: bold;
                }
            }

            .time{
                width: 120px;
                height: 120px;
                margin: auto;
                border: 4px solid lightgray;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .extra{
                display: flex;
                justify-content: space-between;
                gap: 10px;

                .break, .overtime{
                    width: 100%;
                    border: 1px solid lightgray;
                    padding: 2px 10px;
                    border-radius: 5px;
                    text-align: center;
                    line-height: 0%;
    
                    h6{
                        color: black;
                    } 
                    p{
                        font-weight: bold;
                    }
                }
            }
        }
    }
}